import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";
import { SmartLink } from "../utils";
import "./iframe.css";

const PortableTextComponents = {
  types: {
    blogImage: ({ value }) => {
      const { url, caption, ...rest } = value;
      let image = <Image {...rest} width={1280} />;
      if (url) {
        image = <SmartLink href={url}>{image}</SmartLink>;
      }
      return (
        <>
          {image}
          {caption && <figcaption>{caption}</figcaption>}
        </>
      );
    },
    iframe: ({ value }) => {
      const { iframe_html } = value;
      return (
        <div
          dangerouslySetInnerHTML={{ __html: iframe_html }}
          className="iframe-container"
        />
      );
    },
    html: ({ value }) => {
      const { html } = value;
      return <div dangerouslySetInnerHTML={{ __html: html }} />;
    },
  },
  marks: {
    link: ({ value, children }) => {
      const { href } = value;
      if (href) {
        // Disable Gatsby's `Link` bc shortLink are treated as internal.
        // Could we check against a list of shortlinks to use SmartLink ?
        return (
          <SmartLink href={href} preventGatsbyLink={true}>
            {children}
          </SmartLink>
        );
      }
      return children;
    },
    sup: ({ value, children }) => {
      return <sup>{children}</sup>;
    },
  },
};

export { PortableTextComponents };
